export const setApiError = apierror => ({
    type: 'SET_APIERROR',
    apierror
});

export const setAppId = appid => ({
    type: 'SET_APPID',
    appid
});

export const setAppTicketNumber = appticketnumber => ({
    type: 'SET_APPTICKETNUMBER',
    appticketnumber
});


export const setProductQtys = qtys => ({
    type: 'SET_PRODUCTQTYS',
    qtys
});

export const resetProductQtys = qtys => ({
    type: 'SET_PRODUCTQTYS',
    qtys
});

export const setPayments = payments => ({
    type: 'SET_PAYMENTS',
    payments
});

export const resetPayments = payments => ({
    type: 'SET_PAYMENTS',
    payments
});